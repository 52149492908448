import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import {
    genSwitchCalendarShow, genChangeCurrentMonth, genChangeDiffDays, genChangeCurrentWeek, genChangeShowMonth, genChangeShowTrend
} from '../../../../actions/list/calendarLowprice'
import { genSwitchIncludeTax } from '../../../../actions/list/sort'
import { getRoundCalendarWeek, getDiffDaysPriceMonth, getDiffDaysPriceWeek, getMonthsCalendar } from './calendarLPSelector.js'
import CalendarLowPriceBody from '../../../../components/list/top/calendarLowprice/calendarLowPriceBody.js'
import { getToRememberActiveKeysSelector, globalSwitchSelector } from '../../result/flight/baseSelector';


class RoundCalendarContainer extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        //初始化各种数据
        let searchCriteria = this.props.searchCriteria,   //搜索条件
            diffDays = 0,
            searchedDay = moment(searchCriteria.getIn(["flightSegments", 0, "departureDate"])),  //搜索当天日期
            searchedMonthFirst = moment([searchedDay.year(), searchedDay.month(), 1]),  //搜索月份的第一天
            todayMonthFirst = moment([moment().year(), moment().month(), 1]), //当前月份第一天
            diffMonths = searchedMonthFirst.diff(todayMonthFirst, "months") //搜索月份差（用于初始化月视图位置）
        diffMonths = diffMonths > 0 ? diffMonths : 0
        this.props.changeCurrentMonth(diffMonths)           //初始化月份差
        if (searchCriteria.get("flightWay") === 'D') {
            diffDays = moment(searchCriteria.getIn(["flightSegments", 1, "departureDate"])).diff(searchCriteria.getIn(["flightSegments", 0, "departureDate"]), 'days')
        }
        this.props.changeDiffDays(diffDays)                 //初始化往返间隔（只用于展开后切换，不影响展开前）
        let lastDepartDate = this.props.searchCriteria.getIn(["flightSegments", 0, 'departureDate']),
            diffDaysWithSearch = Math.ceil(moment(lastDepartDate).diff(moment(moment().format("YYYY-MM-DD")), 'days'))
        //初始化展开后周视图位置
        if (this.props.searchCriteria.get("flightWay") === 'D') {
            let totalDays = moment(moment().format("YYYY-MM-DD")).add(1, "years").diff(moment(moment().format("YYYY-MM-DD")), "days") - diffDays,
                currentStart = diffDaysWithSearch > 3 ? totalDays - diffDaysWithSearch <= 3 ? totalDays - 6 : diffDaysWithSearch - 3 : 0
            this.props.changeCurrentWeek(currentStart)
        }
    }

    render() {
        let { switchCalendarShow, searchCriteria, containsTax, changeCurrentMonth, currentMonthIndex, switchIncludeTax, changeDiffDays, currentWeekIndex,
            diffDays, showMonth,isTrend ,changeCurrentWeek, changeShowMonth, roundCalendarMonth, roundCalendarWeek,diffDayPriceMonth, diffDayPriceWeek, toRememberActiveKeys,changeShowTrend,globalSwitch} = this.props
        return (
            showMonth ? <CalendarLowPriceBody flightWay="D" isTrend={isTrend} switchShow={switchCalendarShow} searchCriteria={searchCriteria} roundCalendar={roundCalendarMonth} changeDiffDays={changeDiffDays}
                containsTax={containsTax} changeCurrentMonth={changeCurrentMonth} currentMonthIndex={currentMonthIndex} switchIncludeTax={switchIncludeTax}
                diffDayPrice={diffDayPriceMonth} diffDays={diffDays} changeShowMonth={changeShowMonth} showMonth={showMonth} toRememberActiveKeys={toRememberActiveKeys} changeShowTrend={changeShowTrend} globalSwitch={globalSwitch}   />
                : <CalendarLowPriceBody flightWay="D" isTrend={isTrend} switchShow={switchCalendarShow} searchCriteria={searchCriteria} roundCalendar={roundCalendarWeek}  changeDiffDays={changeDiffDays}
                    containsTax={containsTax} switchIncludeTax={switchIncludeTax} showMonth={showMonth} currentWeekIndex={currentWeekIndex} toRememberActiveKeys={toRememberActiveKeys}
                    diffDayPrice={diffDayPriceWeek} diffDays={diffDays} changeCurrentWeek={changeCurrentWeek} changeShowMonth={changeShowMonth} changeShowTrend={changeShowTrend} globalSwitch={globalSwitch}  />
        )
    }
}

const mapStateToProps = (state) => ({
    roundCalendarMonth: getMonthsCalendar(state),
    roundCalendarWeek: getRoundCalendarWeek(state),
    searchCriteria: state.getIn(['list', 'search', 'prevCond']),
    currentMonthIndex: state.getIn(["list", "calendar_lowprice", "currentMonthIndex"]),
    currentWeekIndex: state.getIn(["list", "calendar_lowprice", "currentWeekIndex"]),
    containsTax: state.getIn(["list", "sort", "containsTax"]),
    diffDays: state.getIn(["list", "calendar_lowprice", "roundDiffDays"]),
    diffDayPriceMonth: getDiffDaysPriceMonth(state),
    diffDayPriceWeek: getDiffDaysPriceWeek(state),
    showMonth: state.getIn(['list', 'calendar_lowprice', 'showMonth']),
    isTrend:state.getIn(["list", "calendar_lowprice", "isTrend"]),
    toRememberActiveKeys: getToRememberActiveKeysSelector(state),
    globalSwitch: globalSwitchSelector(state)
})

const mapDispatchToProps = dispatch => ({
    switchCalendarShow: (isShow) => {
        dispatch(genSwitchCalendarShow(isShow))
    },
    changeCurrentMonth: (currentMonthIndex) => {
        dispatch(genChangeCurrentMonth(currentMonthIndex))
    },
    switchIncludeTax: () => {
        dispatch(genSwitchIncludeTax())
    },
    changeDiffDays: (diffDays) => {
        dispatch(genChangeDiffDays(diffDays))
    },
    changeCurrentWeek: (currentWeekIndex) => {
        dispatch(genChangeCurrentWeek(currentWeekIndex))
    },
    changeShowMonth: (showMonth) => {
        dispatch(genChangeShowMonth(showMonth))
    },
    changeShowTrend: (showTrend) => {
        dispatch(genChangeShowTrend(showTrend))
    }
})

export default connect(mapStateToProps, mapDispatchToProps)(RoundCalendarContainer)
